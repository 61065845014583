import React from "react"
import { useNavigate } from "react-router-dom"

import { MenuElem } from "../../components/shared/Menu/Menu";
import CustomSwiper from "../../components/layout/CustomSwiper/CustomSwiper";
import AboutCards from "../../components/layout/About";
import Banner from "../../components/Banner/Banner";

import lunchBackgroundImage from "../../assets/img/bar/lunch.jpg";

import "./style.scss"


export default function Dances() {
  const navigate = useNavigate()
  return (
    <>
      <Banner
        mainTitle="Ланч"
        buttons={[["Меню", "/menu"]]}
        firstParagraph="Ланч 7 дней в неделю по самым доступным ценам в городе."
        secondParagraph="У многих людей ограничено время на обеденный перерыв, поэтому наше меню Ланча представляет собой разнообразные блюда, которые быстро готовятся и подаются к вам на стол. Большие порции по очень доступным ценам. Спокойная обстановка. Быстрое обслуживание. В DOSKI есть возможность собрать ланч с собой и пообедать в удобном для вас месте или организовать доставку обедов для вас и ваших коллег. Ежедневно ланч перетекает в очень уютный вечер с такими же доступными ценами основного меню."
        heroClassName="hero-bar-lunch"
        textShadow={true}
        backgroundImage={lunchBackgroundImage}
      />
      <MenuElem view="lunch" />
      <section data-section="events">
        <CustomSwiper showDetails={true} />
        <button class="primary tg-upper"
          onClick={(e) => {
            navigate("/reservation");
          }}
        >
          Забронировать стол
        </button>
      </section>
      <AboutCards exclude={2} />
    </>
  );
}
