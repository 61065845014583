import React, { useContext, useEffect, useRef, useState } from 'react';

import { useNavigate, Link } from "react-router-dom";

import { Form, Input, Checkbox, Button, Modal, Spin, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';

import { Card } from '../../components/layout/Сard/Card';
import Ticker from '../../components/layout/Ticker/Ticker';
import CustomSwiper from '../../components/layout/CustomSwiper/CustomSwiper';
import { PersonalDataModal, usePersonalDataModal } from '../../components/shared/PersonalData';

import { RestaurantContext } from '../../providers/restaurant.js';

import {useScrollOnRender} from "../../utils/hooks/scroll.js"
import useResizeWindows from '../../utils/hooks/useResizeWindow.js';

import { ReactComponent as FollowLinkSvg } from '../../assets/icons/followLink.svg';

import ArrowDownSvg from '../../assets/icons/greenArrowDown.svg';
import reservedImg from '../../assets/images/reserved-new.png';
import reservedImgMob from '../../assets/images/reserved-mob-new.png';
import PlatesImg from '../../assets/img/Main.png';
import PlatesImgMobile from '../../assets/img/Main_768.png';

import mobAppBanner from '../../assets/img/mobile-app-banner.png'
import mobAppBannerMobile from '../../assets/img/mobile-app-banner-mobile.png'

import card1Img from '../../assets/images/card1_2560.png';
import card2Img from '../../assets/images/card2_2560.png';
import card3Img from '../../assets/images/card3_2560.png';
import card4Img from '../../assets/images/card4_2560.png';

import { EventDetailsModal, eventFilterFactory, gridImagesMapper } from '../Events/Grid.jsx'

import { API_HOST, API_URL, SETTINGS_API_URL } from '../../App.js';
import { itemRules } from '../../utils/helpers/constants.js';

import './styles.scss';
import { getAddressOptions } from '../../utils/restaurant.js';


const Main = () => {
  const navigate = useNavigate();

  useScrollOnRender(true)

  const width = useResizeWindows()

  const [gridImagesArray, setGridImagesArray] = useState()

  const {
    places, current,
  } = useContext(RestaurantContext)

  const feedbackForm = useRef()

  useEffect(() => {
    if (places?.[current]) {
      const params = new URLSearchParams([
        ['rest_id', places?.[current].id]
      ])
      fetch(API_URL + `/events?${params.toString()}`).then(
          response => response.json()
      ).then(
          content => {
            setGridImagesArray(gridImagesMapper(content))
          }
      ).catch(
          e => {
            console.error(e)
            setGridImagesArray(null)
          }
      )
    }
  }, [places, current])

  const [feedbackSubmission, setFdBckS] = useState({
    showing: false,
  })
  function closeFdBckS(e) {
    setFdBckS({...feedbackSubmission, error: undefined, showing: false})
  }
  function submitFdBck() {
    setFdBckS(prev => ({...prev, showing: true, pending: true}))
  }
  const feedbackHandler = (values) => {
    let failed = false
      , fd = new FormData()

    submitFdBck()
    for (let v in values) {
      fd.append(v, values[v])
    }
    fetch(API_HOST + '/feedback', {
      method: 'POST',
      body: fd,
    }).then(
      response => {
        if (response.status >= 400) {
          failed = true
          return response.text()
        } else return response.json()
      }
    ).then(
      content => {
        if (content.status === 'error' || failed) {
          failed = true
          return Promise.reject(content)
        }
      }
    ).catch(
      e => {
        console.error(e)
        setFdBckS(prev => ({...prev, ...e}))
      }
    ).finally(
      () => {
        setFdBckS(prev => ({...prev, pending: false}))
      }
    )
  }

  const {
    personalDataModal,
    close: closePersonalDataModal,
    open: openPersonalDataModal,
  } = usePersonalDataModal()

  const [eventDetailsModal, setEventDetailsModal] = useState({
    open: false,
  })

  const [loadingBanner, setLoadingBanner] = useState(true)
  const [desktopBannerSrc, setDesktopBannerSrc] = useState()
  const [mobileBannerSrc, setMobileBannerSrc] = useState()

  useEffect(() => {
    fetch(SETTINGS_API_URL + '/main_banners').then(
      res => res.json()
    ).then(
      content => {
        setDesktopBannerSrc(API_HOST + content.desktop)
        setMobileBannerSrc(API_HOST + content.mobile)
      }
    ).catch(
      e => {
        console.log(e)
      }
    ).finally(
      () => setLoadingBanner(false)
    )
  }, [])

  return (
    <>
    <div className='main'>
      <div className='parent'>
        <div className='container child' style={{
          marginInline: 0,
          textAlign: 'center',
          width: '100%',
        }}>
          {
            !loadingBanner && <img style={width > 768 ? {width: '100%'} : {maxWidth: '100%'}} src={width <= 768 ? mobileBannerSrc : desktopBannerSrc} alt='<Баннер для главной страницы бара "Доски"' />
          }
        </div>
        <div className='childBody main-child'>
          <div className='submenu container'>
            <div className='item'
              onClick={e => {navigate('/karaoke')}}
            >КАРАОКЕ</div>
            <div className='item'
              onClick={e => {navigate('/lunch')}}
            >ЛАНЧ</div>
            <div className='item'
              onClick={e => {navigate('/dance')}}
            >ТАНЦЫ</div>
            <div className='item'
              onClick={e => {navigate('/bar')}}
            >БАР</div>
          </div>
          <div className='area'>{
            loadingBanner && <Spin size="large" tip="Идёт загрузка..." style={{
              marginBlockStart: '20%',
            }}>
              <div className="content" />
            </Spin>
          }</div>

          <div className='block container'>
            <div className='link-cards'>
              <div className='link-card'>
                <div className='card-bg parent'>
                  <div className='black-left-bg child'></div>
                  <div className='reserved-image child'>
                    <img src={width > 768 ? reservedImg : reservedImgMob} alt=''/>
                  </div>
                  <div className='link-card-body link-card-body-first child'>
                    <div className='titleSection link-card-title'
                      onClick={() => navigate("/reservation")}
                    >Бронирование</div>
                    <div className='link link-hidden'>
                      <Button className='go' onClick={() => navigate("/reservation")}>
                        <div className='link-text'>Перейти</div>
                        <FollowLinkSvg/>
                      </Button>
                      {/* <LinkArrowSvg /> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className='link-card'>
                <div className='card-bg parent'>
                  <div className='black-right-bg child'></div>
                  <div className='two-plates-image child' style={{width: '100%'}}>
                    <img src={width > 448 ? PlatesImg : PlatesImgMobile} alt='наши блюдА'/>
                  </div>
                  <div className='link-card-body child'>
                      <div className='titleSection link-card-title'
                        onClick={() => navigate("/menu")}
                      >Меню</div>
                      <div className='link link-hidden'>
                        <Button className='go' onClick={() => navigate("/menu")}>
                          <div className='link-text'>Перейти</div>
                          <FollowLinkSvg/>
                        </Button>
                        {/* <LinkArrowSvg /> */}
                      </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className='area2'></div>

          <Ticker name={"news"}/>

          <div className='block container'>
            <CustomSwiper />
          </div>

          <div className='block container block-with-cards'>
            <div className='wrapperColumns'>
              <div className='firstColumn'>
                <h1 className='meta'><div className='titleSection'>Что такое doski</div></h1>
              </div>
              <div className='cards'>
                <Card 
                  direction='left'
                  tag='Вечером' 
                  title='Бар' 
                  text='Вас ждет огромное количество разнообразных коктейлей, напитков и блюд по самым доступным ценам в городе'
                  className='titleSection'
                  src={card1Img}
                  id='1'
                  url="/bar"
                />
                <Card 
                  direction='right'
                  tag='Ночью' 
                  title='ТАНЦЫ' 
                  text='Вечеринки каждый день. Лучшие DJ, ведущие и артисты вашего города'
                  className='titleSection'
                  src={card2Img}
                  id='2'
                  url="/dance"
                  />
                <Card 
                  direction='left'
                  tag='Днем' 
                  title='ЛАНЧ' 
                  text='Разнообразное меню и большие порции по доступным ценам 7 дней в неделю'
                  className='titleSection'
                  src={card3Img}
                  id='3'
                  url="/lunch"
                  />

                <Card 
                  direction='right'
                  tag='Всегда' 
                  title='КАРАОКЕ' 
                  text='Стильные и современные залы с широким выбором песен на любой вкус и жанр'
                  className='titleSection'
                  src={card4Img}
                  id='4'
                  url="/karaoke"
                  />

              </div>
            </div>
          </div>

          <div className='block container block-with-grid-images-1'>
            <div className='gridParent'>
              {/* {[0,1,2,3,4,5,6,7,8].map((item, index) => { */}
              {gridImagesArray ? gridImagesArray.filter(
                // i => i.restarauntId === null || i.restarauntId === places[current]?.id
                eventFilterFactory(places?.[current]?.id)
              ).map((item, index) => {

                return <div className={`gridChild gridChild-${index} grid-image-wrapper`}
                  onClick={e => {
                    setEventDetailsModal({
                      open: true,
                      onCancel: (e) => setEventDetailsModal({
                        open: false
                      }),
                      event: item,
                    })
                  }}
                >
                  {/* <div className='grid-image-wrapper'> */}
                    <img src={item.src} alt={item.alt} />
                  {/* </div> */}
                </div>
              })
              : (
                (gridImagesArray === undefined && "ожидаем...") ||
                (gridImagesArray === null && "ошибка при получении данных, обратитесь в developer console")
              )
            }
            </div>
          </div>

          <div className='block wrapperColumns formWrapperColumn container'>
            <div className='firstColumn'>
              <div className='titleSection'>Отзывы<br/>о DOSKI</div>
            </div>
            <div className='secondColumn'>
              <Form
                ref={feedbackForm}
                onFinish={feedbackHandler}
              >
                <Form.Item label='Ваше имя' name='name'
                  rules={itemRules}
                >
                  <Input />
                </Form.Item>
                <Form.Item label='Телефон' name='phone'
                  rules={itemRules.concat([{
                    pattern: /\(\d{3}\)\d{3}-\d{2}-\d{2}/,
                    validateTrigger: 'blur',
                    message: "Телефон заполнен не до конца",
                  }])}
                  data-id='phone-try'
                >
                  <MaskedInput
                    defaultValue=''
                    mask={'(000)000-00-00'}
                    addonBefore={<Form.Item name="prefix" noStyle>
                      <div>+7</div>
                    </Form.Item>}
                  />
                </Form.Item>
                <Form.Item label="Бар" name="restaurant" rules={itemRules}
                  style={{
                    width: '100%',
                  }}
                >
                  <Select
                    options={getAddressOptions(places, places?.[current].id)}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    suffixIcon={<div className='arrow-down-svg'><img src={ArrowDownSvg} alt="arrow down" /></div>}
                  />
                </Form.Item>
                <Form.Item label='Комментарий' name='description'
                  rules={itemRules}
                >
                  <Input placeholder='Ваш отзыв'/>
                </Form.Item>
                <Form.Item>
                  <Checkbox required>С <Link to="/agreement" onClick={e => {
                    e.preventDefault()
                    openPersonalDataModal()
                  }}>условиями обработки персональных данных</Link> согласен</Checkbox>
                </Form.Item>
                <Button htmlType="submit">
                  Отправить
                </Button>
              </Form>
            </div>
          </div>
          {/* потомкам в назидание */}
          {/* <div className='advertising-wrapper container'>
            <div className='application-wrapper'>
            
              <div className='advertising-content-wrapper'>
                <div className='advertising-content'>

                  <div className='advertising-text'>Скачайте новое Мобильное приложение</div>
                  <div className='advertising-buttons'>
                    <div className='specific-button'>
                      <img src={appStoreImg} alt="AppStore banner"/>
                    </div>
                    <div className='specific-button'>
                      <img src={googlePlayImg} alt='Google Play banner' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='green-bg child'></div>

              <div className='child'>
                <div className='handle-black'>
                  <div className='black-bg child'></div>
                  <div className='handle-bg child'>
                    <img src={handleImg} alt='Robotic hand with a telephone'/>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className='mobile-apps-wrapper'>
            <img src={width <= 376 ? mobAppBannerMobile : mobAppBanner} alt="роботическая рука держит телефон, а вы скоро сможете скачать наше классное мобильное приложение (потому что его делают другие люди, в отличие от этого веб-сайта)" />
          </div>
        </div>
      </div>
    </div>
    <Modal
        open={feedbackSubmission.showing}
        onCancel={closeFdBckS}
        footer={null}
      >
        <div className='request-modal'>
        {
          feedbackSubmission.pending ?
          <p>Ожидаем сервер...</p>
          : feedbackSubmission.error ?
          <p>Произошла ошибка: {feedbackSubmission.details}</p>
          : (<div className='good'>
              <div className='header'>Ваш отзыв был принят</div>
              <hr/>
              <div className='body'>Спасибо, что были с нами, ваше мнение важно!</div>
            </div>)
        }
        </div>
      </Modal>
      <PersonalDataModal
        {...personalDataModal} close={closePersonalDataModal}
      />
      <EventDetailsModal
        {...eventDetailsModal}
      />
    </>
  )
}

export default Main;