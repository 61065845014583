import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/layout/Header/Header';
import Main from './pages/Main/Main';
import Reservation from './pages/Reservation/Reservation';
import Menu from './pages/Menu/Menu';
import {default as Order} from './pages/Preorder/Preorder'
import Job from './pages/Job/Job';
import Bar from './pages/Bar/Bar'
import Footer from './components/layout/Footer/Footer';
import Karaoke from './pages/Karaoke/Karaoke';
import Dances from './pages/Dances/Dances';
import Lunch from './pages/Lunch/Lunch';
import Partner from './pages/Partner/Partner';
import Events from './pages/Events/Events';

import { RestaurantContext } from './providers/restaurant';
import { ReservationContext, PreorderContext } from './providers/reservation';
import { MenueContext } from './providers/menue';

import useTitle from './hooks/useTitle';
import { haversine } from './utils/geo'

import './App.scss';


// 31.129.42.223:8000 backend.doski.bar
// TODO: брать из .env
export const API_HOST = 'https://backend.doski.bar'
export const API_URL = API_HOST + '/api'
export const SETTINGS_API_URL = API_URL + '/settings'


function App() {
  // TODO: useMenu hook
  const [places, setPlaces] = useState()
  const [current, setCurrent] = useState(-1)
  const [reservationId, setReservationId] = useState()
  const [reservation, setReservation] = useState()
  const [preorderState, setPreorderState] = useState(
      {
          "guests": 1,
          
          "comment": "about",
          
          "menue": [
              // {
              //     "id": 416,
              //     "quantity": 2,
              //     "price": 159,
              //     "category": 2,
              // }
          ],
          showing: false,
          formed: false,
      }
  )

  const setReservationGuests = useCallback((v) => {
    setPreorderState(prev => ({...prev, guests: v}))
  }, [])
  const setReservationFIO = useCallback((v) => {
    setPreorderState(prev => ({...prev, fio: v}))
  }, [])
  const setReservationDate = useCallback((v) => {
    setPreorderState(prev => ({...prev, date: v}))
  }, [])
  const setReservationPhone = useCallback((v) => {
    setPreorderState(prev => ({...prev, phone: v}))
  }, [])

  useTitle()

  const [menue, setMenue] = useState()

  useEffect(() => {
    let serverClosestId, serverClosestDistance
    fetch(API_URL + "/addresses").then(
      response => {
        const dd = response.headers.get('X-Doski-Distance');
        [serverClosestId, serverClosestDistance] = dd.split(';')[0].split(',')
        return response.json()
      }
    ).then(
      content => {
        setPlaces(content)
        console.log("geo from server:", serverClosestId, serverClosestDistance)
        setCurrent(content.findIndex(i => i.id == Number(serverClosestId)) || (content.length - 1))
      }
    ).catch(
      e => {
        console.error(e)
      }
    )
    fetch(SETTINGS_API_URL + '/reservation_hours').then(
      response => response.json()
    ).then(
      content => {
        setReservation({...content})
      }
    )
  }, [])

  // useEffect(() => {
  //   let clientClosestRest = 0, clientClosestDistance = 99999999999
  //   if (!navigator.geolocation) {
  //     console.warn("!navigator.geolocation")
  //   } else {
  //     navigator.geolocation.getCurrentPosition(
  //       ({timestamp, coords}) => {
  //         console.info("geolocation request at", timestamp)
  //         places?.forEach(
  //           (p, idx) => {
  //             const [lat_, lng_] = p.coordinates.split(',')
  //             const pLat = Number(lat_), pLng = Number(lng_)
  //             const d = haversine(
  //               coords.latitude, coords.longitude,
  //               pLat, pLng
  //             )
  //             if (d < clientClosestDistance) {
  //               clientClosestDistance = d
  //               clientClosestRest = idx
  //             }
  //           }
  //         )
  //         console.info("clientClosestDistance", clientClosestDistance)
  //         console.info("clientClosestRest", clientClosestRest)
  //         setCurrent(clientClosestRest)
  //       },
  //       e => {
  //         console.error("ошибка при определении геолокации", e)
  //       },
  //       {
  //         enableHighAccuracy: true,
  //       }
  //     )
  //   }
  // }, [places])

  const microdataBlock_ = useMemo(() => {
    const p = places?.[current]
    return p ? <div id="rest-microdata">
    <div itemscope itemtype="http://schema.org/Organization">
      <p itemprop="name">DOSKI</p>
      <p itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">{p.address}</span>
        <span itemprop="postalCode">{p.postal_index}</span>
        <span itemprop="addressLocality">{p.city}</span>
      </p>
      <p itemprop="telephone">{p.phone}</p>
  </div></div> : null}, [places, current])

  return (
    <div className="App">
      <RestaurantContext.Provider value={{
        places, current, setCurrent
      }}>
      <Header />
      <ReservationContext.Provider value={{
        id: reservationId,
        ...reservation,
      }}>
      <PreorderContext.Provider value={{
        id: reservationId, setReservationId,
        setReservationGuests, setReservationFIO,
        setReservationDate, setReservationPhone,
        preorderState, setPreorderState,
      }}>
      <MenueContext.Provider value={{menue, setMenue}}>
      <Routes>
        <Route path="/" element={<Main />} />

        <Route path="/menu" element={<Menu />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/order" element={<Order />} />

        <Route path="/partner" element={<Partner />} />
        <Route path="/events" element={<Events />} />
        <Route path="/job" element={<Job />} />

        <Route path="/bar" element={<Bar />} />
        <Route path="/lunch" element={<Lunch />} />
        <Route path="/dance" element={<Dances />} />
        <Route path="/karaoke" element={<Karaoke />} />
      </Routes>
      </MenueContext.Provider>
      </PreorderContext.Provider>
      </ReservationContext.Provider>
      <Footer />
      </RestaurantContext.Provider>
      {microdataBlock_}
    </div>
  );
}

export default App;
