import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs'
import {
  Form, Button, Input, Select, Space, DatePicker,
  Modal,
} from 'antd';
import { MaskedInput } from 'antd-mask-input';

import Counter from '../../components/shared/Counter/Counter';
import VisitorRulesModal, { RulesModal } from './Modals/RulesModal';
import RequestModal from './Modals/RequestModal';

import { ReactComponent as ArrowDownSvg } from '../../assets/icons/greenArrowDown.svg';
import reservedImg from '../../assets/images/reserved-new.png';
import { ReactComponent as CalendarSvg } from '../../assets/icons/calendar.svg';
import { ReactComponent as ClockSvg } from '../../assets/icons/clock.svg';
import CustomCheckbox from '../../components/shared/СustomCheckbox/CustomCheckbox';

import useResizeWindows from '../../utils/hooks/useResizeWindow';
import { useScrollOnRender } from '../../utils/hooks/scroll';
import { isWeekEnd, extractHour, extractMinute } from '../../utils/helpers/functions';
import { itemRules } from '../../utils/helpers/constants'
import { getAddressOptions } from '../../utils/restaurant'

import { RestaurantContext } from '../../providers/restaurant';
import { ReservationContext, PreorderContext } from '../../providers/reservation'

import { API_HOST } from '../../App';

import './styles.scss'


const prefixSelector = (
  <Form.Item name="prefix" value="+7" noStyle>
    <div>+7</div>
  </Form.Item>
);

const { Item } = Form;
const { TextArea } = Input;

const formConfig = {
  address: "Ресторан",
  peoplesAmount: "Количество человек",
  day: "Дата",
  time: "Время",
  name: "Имя",
  phone: "Телефон",
}

const timePickerStyle = {
  paddingInline: 0,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
}

export function checkReservationData({
  values
}) {
  const fd = new FormData()
      , missing = new Map()
  let error = "некорректно заполненная форма:\n"

  for (const prop in values) {
    const s = String(prop)
    switch (s) {
    case "day":
      const d = new Date(Date.parse(values[s].toString()))
      fd.append('date', d.toLocaleDateString('en-GB'))
      continue
    case "phone":
      if (s === "phone" && values[s]?.includes('_')) {
        missing.set(s, "заполнен не до конца")
        continue
      }
      if (!values[s]) {
        missing.set(s, null) 
        continue
      }
      break
    default:
      console.log("default prop", s, "with value", values[s])
    }
    if (values[prop]) fd.append(s, values[prop])
  }
  if (missing.size > 0) {
    missing.forEach(
      (v, k) => {
        error += `${formConfig[k]}: ${v || 'пусто'}\n`
      }
    )
  }
  return {
    fd, missing, error,
  }
}

function ReservationForm({
  isFocus = false,
  formRef,
  onFinish, showRules, showVisitorRules,
}) {
  const width = useResizeWindows()
      , {
        places: addressesArray, current,
      } = useContext(RestaurantContext)
      , {
        rangeWeekdays, rangeWeekends,
      } = useContext(ReservationContext)

  const [canSetTime, setCanSetTime] = useState(false)

  // const [restVal, setRestVal] = useState()
  const [dayVal, setDayVal] = useState()

  const dayChangeHandler = useCallback((date, dateString) => {
    if (date) {
      setCanSetTime(true)
      setDayVal(date)
    }
    else {
      setCanSetTime(false)
      setDayVal(null)
    }
  }, [])

  const timeOptions = useMemo(() => {
    let target = rangeWeekdays

    if (isWeekEnd(new Date(dayVal))) {
      target = rangeWeekends
    }
    const sH = extractHour(target?.[0])
      , eH = extractHour(target?.[1])
      , sM = extractMinute(target?.[0])
      , eM = extractMinute(target?.[1])
      , ret = []
    
    for (let h = sH; h <= eH; h++) {
      const label00 = `${h}:00`
          , label30 = `${h}:30`
          , obj00 = {label: label00, value: label00}
          , obj30 = {label: label30, value: label30}
      if (h === eH) {
        ret.push(obj00)
        if (eM >= 30) {
          ret.push(obj30)
        }
      } else {
        if (sM === 0) {
          ret.push(obj00) 
        }
        if (sM < 30) {
          ret.push(obj30)
        }
      }
    }
    return ret
  }, [rangeWeekdays, rangeWeekends, dayVal])

  const timeDDMaxWidth = useMemo(() => {
    // почти css только js
    let ret = 450
    if (width < 1440) {
      ret = 200
    } else if (width < 1800) {
      ret = 325
    }
    return ret
  }, [width])

  return (
    <div className='reservation-form container'>
      <Form
        form={formRef}
        onFinish={
          onFinish
        }
        onFinishFailed={failedValues => console.log('failedValues: ', failedValues)}
        initialValues={
          {
            peoplesAmount: 1
          }
        }
      >
        <Item
          label="Выберите ресторан" 
          name="address"
          rules={itemRules}
          data-select="no-padding"
          style={{
            width: '100%',
          }}
        >
          <Select
            showSearch
            placeholder=""
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            // defaultValue={addressesArray?.[current]?.id}
            // value={[restVal]}
            options={getAddressOptions(addressesArray, addressesArray?.[current].id)}
            autoFocus={isFocus}
            suffixIcon={<div className='arrow-down-svg'><ArrowDownSvg /></div>}
          />
        </Item>

        { width > 770 ? <>
          <Item label=''>
            <Item  
              label={formConfig['peoplesAmount']}
              name="peoplesAmount"
              style={{ 
                display: 'inline-block', 
                width: `calc(34% - ${width >= 2000 ? '26px' : width >= 1440 ? '11px' : width >= 1024 ? '11px' : '8px'})`, 
                marginBottom: '0' }}
              >
              <Counter />
            </Item>

            <Item 
              label='date'
              style={{ 
                display: 'inline-block', 
                width: `calc(66% - ${width >= 2000 ? '5px' : width >= 1440 ? '-1px' : width >= 1024 ? '0px' : '8px'})`, 
                margin: `0 0 0 ${width >= 2000 ? '31px' : width >= 1440 ? '10px' : width >= 1024 ? '10px' : '16px'}` }}
            >
              <Space.Compact>
                <Item
                  label='Дата'
                  name='day'
                  rules={itemRules}
                  style={{ display: 'inline-block', width: '50%', marginBottom: '0'}}
                >
                  <DatePicker  
                    size='large'  
                    placeholder='' 
                    suffixIcon={<CalendarSvg />}
                    disabledDate={current => {
                      return current && current < dayjs().subtract(1, 'day')
                    }}
                    showNow={false}
                    value={dayVal}
                    onChange={dayChangeHandler}
                  />
                </Item>

                <Item
                  label='Время'
                  name='time'
                  rules={itemRules}
                  style={{ display: 'inline-block', width: '50%', marginBottom: '0'}}
                >
                  <Select
                    disabled={!canSetTime}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    placeholder=""
                    options={timeOptions}

                    suffixIcon={<ClockSvg className="clock-icon" />}
                    dropdownStyle={{
                      maxWidth: timeDDMaxWidth,
                      width: '100%'
                    }}
                    style={timePickerStyle}
                  />
                </Item>
              </Space.Compact>
            </Item>
          </Item>
        </>
        : <>
            <Item  
              label="Количество человек" 
              name="peoplesAmount"
              style={{ display: 'inline-block', width: '100%' }}
              // required
              // rules={[{required: true, message: 'Поле не заполнено'}]}
              >
              <Counter />
            </Item>

            <Item 
              label='date'
              rules={itemRules}
              style={{ display: 'inline-block', width: '100%', marginBottom: '0'}}
            >
              <Space.Compact>
                <Item
                  label='Дата'
                  name='day'
                  style={{ display: 'inline-block', width: '50%'}}
                >
                  <DatePicker
                    size='large' 
                    placeholder=''
                    suffixIcon={<CalendarSvg />}
                    disabledDate={current => {
                      return current && current < dayjs().subtract(1, 'day')
                    }}
                    showNow={false}
                    value={dayVal}
                    onChange={dayChangeHandler}
                  />
                </Item>

                <Item
                  label='Время'
                  name='time'
                  style={{ display: 'inline-block', width: '50%'}}
                >
                  <Select
                    disabled={!canSetTime}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    placeholder=""
                    options={timeOptions}

                    suffixIcon={<ClockSvg className="clock-icon" />}
                    dropdownStyle={{
                      maxWidth: width > 672 ? 200 : 'calc(50vw - 24px)',
                      width: '100%',
                    }}
                    style={timePickerStyle}
                  />
                </Item>
              </Space.Compact>
            </Item>
          </>
        }

        {width > 770 ? <>
          <Item label=''>
            <Item 
              label='Имя'
              name='name'
              rules={itemRules}
              style={{ display: 'inline-block', width: `calc(50% - ${width >= 2000 ? '15px' : '8px'})`, marginBottom: '0' }}
            >
              <Input />
            </Item>

            <Item
              data-id="phone-try"
              label='Телефон'
              name='phone'
              rules={itemRules.concat([{
                pattern: /\(\d{3}\)\d{3}-\d{2}-\d{2}/,
                validateTrigger: 'blur',
                message: "Телефон заполнен не до конца",
              }])}
              style={{ display: 'inline-block', width: `calc(50% - ${width >= 2000 ? '15px' : '8px'})`, margin: `0 0 0 ${width >= 2000 ? '30px' : '16px'}` }}
            >
              <MaskedInput
                defaultValue=''
                mask={'(000)000-00-00'}
                addonBefore={prefixSelector}
              />
            </Item>
          </Item>
        </>
        : <>
            <Item 
              label='Имя'
              name='name'
              rules={itemRules}
              style={{ display: 'inline-block', width: '100%' }}
            >
              <Input />
            </Item>

            <Item 
              label='Телефон'
              name='phone'
              rules={itemRules.concat([{
                pattern: /\(\d{3}\)\d{3}-\d{2}-\d{2}/,
                validateTrigger: 'blur',
                message: "Телефон заполнен не до конца",
              }])}
              style={{ display: 'inline-block', width: '100%' }}
            >
              <MaskedInput defaultValue='' mask={'(000)000-00-00'} addonBefore={prefixSelector}/>
            </Item>
        </>
        }

        <Item
          label='Пожелания'
          name='Wishes'
        >
          <TextArea 
            // placeholder='Ваш текст'
            autoSize={{
              minRows: width > 375 ? 3.5 : 3.25,
              maxRows: 8
            }}
          />
        </Item>
        <Item label=''>
          <Item name='personalData'
            style={width > 768 ? { display: 'inline-block', width: 'calc(50% - 4px)', marginBottom: '0', marginRight: '8px' } : {}}
          >
            <CustomCheckbox
              openVisitingRules={showVisitorRules}
            />
          </Item>

          <Button data-magic="правим-уродства"
            htmlType='submit'
            style={ width > 768 ? { display: 'inline-block', width: 'calc(50% - 4px)', marginBottom: '0' } : {}}
          >Оставить заявку</Button>
        </Item>

        <div className='reservation-rules-wrapper'>
          <div className='reservation-rules'
            onClick={showRules}
          >Правила бронирования</div>
        </div>
      </Form>
    </div>
  )
}

export function ReservationFormModal({
  open, onCancel, ...props
}) {
  const width = useResizeWindows()
  const {
    pending, result, error,
    ...otherProps
  } = props
  const content = useMemo(() => {
    let ret
    if (pending) {
      ret = <p className="reservation-pending tg-authentic tg-upper">Идёт обработка...</p>
    } else {
      switch (result) {
        case true:
          ret = <div className='request-modal'>
                  <div className='good'>
                    <div className='body'>
                    Ваша заявка принята.<br/>С&nbsp;вами свяжутся в&nbsp;ближайшее время!
                    </div>
                    <Link to="/order">
                      <p className="preorder-link">
                        Составить предзаказ?
                      </p>
                    </Link>
                  </div>
                </div>
          break
        case false:
          ret = <p>Не вышло{error ? `(${error})` : ''}, попробуйте позже</p>
          break
        default:
          ret = <ReservationForm {...otherProps}/>
      }
    }
    return ret
  }, [pending, result, otherProps, error])
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className={props.className || undefined}
      footer={null}
      // closeIcon={
      //   width < 448 ? <div
      //     className='modal-mobile-closer'
      //   ></div>
      //   : undefined
      // }
      style={width < 448 ? {
        position: 'relative',
        top: 20,
      } : {}}
    >
      <p className='tg-authentic tg-upper'>Бронирование</p>
      {content}
    </Modal>
  )
}

const Reservation = () => {
  const context = useContext(PreorderContext)

  useScrollOnRender(true)

  const [isFocus, setIsFocus] = useState(false);
  const [openVisitorRules, setOpenVisitorRules] = useState(false);
  function showVisitorRules(e) {
    setOpenVisitorRules(true)
  }

  const [openRules, setOpenRules] = useState(false);
  function showRules(e) {
    setOpenRules(true)
  }

  const [openRequest, setOpenRequest] = useState(false);
  const [valuesToSubmit, setValuesToSubmit] = useState()
  const [showingRequestModal, setShowingRequestModal] = useState(false)
  const [result, setResult] = useState()

  const [formRef] = Form.useForm()

  useEffect(() => {
    if (openRequest) {
      setShowingRequestModal(true)
      const {
        fd, missing, error,
      } = checkReservationData({values: valuesToSubmit})

      if (missing.size > 0) {
        let error = "некорректно заполненная форма:\n"
        missing.forEach(
          (v, k) => {
            error += `${formConfig[k]}: ${v || 'пусто'}\n`
          }
        )
        setResult({
          error
        })
        return
      }  

      let statusCode
      fetch(API_HOST + '/reservation', {
        method: "POST",
        body: fd
      }).then(
        async response => {
          statusCode = response.status
          if (statusCode < 500) return await response.json()
          else return Promise.reject(await response.text())
        }
      ).then(
        json => {
          const {
            status
          } = json
          if (status === "error") {
            setResult({
              status: statusCode,
              error: json.message,
            })
          } else {
            setResult(
              {
                success: true,
                ...json,
              }
            )
            context.setReservationId(json.id)
            context.setReservationGuests(fd.get('peoplesAmount'))
            context.setReservationFIO(fd.get('name'))
            context.setReservationDate(valuesToSubmit['day'])
            context.setReservationPhone(valuesToSubmit['phone'])
            formRef.resetFields()
          }
        }
      ).catch(
        e => {
          console.error("caught", e, "during the fetch")
          let resultString = e.toString()

          if (e instanceof TypeError) {
            resultString = "Не удалось отправить запрос, верятнее всего, по всей видимости, наш сервер испытывает проблемы (вероятно, он вовсе не запущен)... Попробуйте повторить запрос ещё раз позже"
          } else {
              console.warn("no path dynamic type of", e)
          }
          setResult(
            {
              status: statusCode,
              error: resultString,
            }
          )
        }
      ).finally(() => {
        setOpenRequest(false)
      })
    }
  }, [openRequest, context, valuesToSubmit, formRef])

  return (
    <div className='reservation'>

    <VisitorRulesModal open={openVisitorRules} setOpen={setOpenVisitorRules}/>
    <RulesModal open={openRules} setOpen={setOpenRules}/>
    <RequestModal
      open={showingRequestModal}
      setOpen={setShowingRequestModal}
      result={result}
      setResult={setResult}
      onFinish={(...args) => {
        console.log("закрываем-с модалий", ...args)
      }}
    />

    <div className='bg-wrapper parent container'>
      <div className='black-bg child'></div>
      <div className='reserved-bg child'>
        <div className='reserved-bg-image-wrapper'>
          <img src={reservedImg} alt='табличка "Reserved"' />
        </div>
      </div>
      <div className='header-content child'>
        <div className='header-content-flex'>
          <h1 className='meta'><div className='header-text'>Бронирование</div></h1>
          <div className='greenButton'>
            <Button onClick={showRules}>правила бронирования</Button>
          </div>
        </div>
      </div>
    </div>

    <ReservationForm
      isFocus={isFocus}
      onFinish={values => {
        setOpenRequest(true)
        setValuesToSubmit(values)
      }}
      formRef={formRef} // блевотный antd раз приходится такой ерундой страдать
      // типа в голом реакте то же самое, только не надо ещё стили ничьи переопределять (только свои)
      showRules={showRules}
      showVisitorRules={showVisitorRules}
    />
    </div>
  )
}

export default Reservation;
